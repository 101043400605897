import React from 'react';
import { useLocation } from 'react-router-dom';
import { ApiUrl } from '../../Components/API/Api';
import useCachedData from '../../useCachedData';

function LoretoGlobally() {
    const location = useLocation();
    const url = location.pathname;

    const path = {
        "/loreto-global": 14,
        "/mary-ward-international": 15,
        "/lay-associates": 16,
        "/useful-links": 17,
    };
    
    const pageId = path[url] ? path[url] : url;

    const { data, loading } = useCachedData(`${ApiUrl}/get/Pages/${pageId}`, pageId);

    if (loading) {
        return (
            <div className="text-center mt-5">
                <b>Loading...</b>
            </div>
        );
    }

    if (!data || data.length === 0) {
        return (
            <div className="text-center mt-5">
                <b>No Data Available</b>
            </div>
        );
    }

    const filteredData = data.filter((item) => item.id === pageId);

    if (filteredData.length === 0) {
        return (
            <div className="text-center mt-5">
                <b>No matching data found</b>
            </div>
        );
    }

    return (
        <>
            <div className="container subpage mt-5">
                <div className="row">
                    <div className="col-lg-12">
                        {filteredData.map((item) => (
                            <div key={item.id}>
                                <div
                                    style={{ textAlign: "justify" }}
                                    dangerouslySetInnerHTML={{
                                        __html: `<style>table { width: 100%; max-width: 100%; border-collapse: collapse; } table, th, td { border: 1px solid #ddd; padding: 8px; text-align: left; } @media (max-width: 600px) { table, th, td { display: block; width: 100%; box-sizing: border-box; } } </style>${item.content}`,
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <style jsx>{`
        @media (max-width: 768px) {
          .content {
            text-align: justify !important;
            font-size: 14px !important;
          }

          .content img {
            max-width: 100% !important;
            height: auto !important;
          }

          .content table {
            width: 100% !important;
            border-collapse: collapse !important;
          }

          .content table th,
          .content table td {
            padding: 8px !important;
            border: 1px solid #ddd !important;
          }
        }
      `}</style>
        </>
    );
}

export default LoretoGlobally;