import { useQuery } from 'react-query';
import axios from "axios";
import { ApiUrl } from "./API/Api";
import { Link } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import "./gallerystyle.css";

const GalleryNews = () => {
    const galleryImagesQuery = useQuery('galleryImages', () =>
        axios.get(`${ApiUrl}/get/gallery_images`).then((response) => response.data.data)
    );

    const newsletterDataQuery = useQuery('newsletterData', () =>
        axios.get(`${ApiUrl}/get/Newsletter`).then((response) => {
            const allData = response.data.data;
            allData.sort((a, b) => new Date(b.eventdate) - new Date(a.eventdate));
            const latestData = allData.slice(0, 5);
            return latestData;
        })
    );

    const filtereddata = newsletterDataQuery.data?.filter(
        (item) => item.category_name === 'News Letter'
    );

    const images =
        galleryImagesQuery.data?.map((image) => ({
            original: image?.image || '',
            thumbnail: image?.image || '',
        })) || [];

    if (galleryImagesQuery.data) {
        galleryImagesQuery.data.forEach((image) => {
            images.push({
                original: image.image || '',
                thumbnail: image.image || '',
            });
        });
    }

    return (
        <>
            <section className="gap blog" style={{ backgroundColor: '#f2f2f2' }}>
                <div className="container mt-3">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="section-headings">
                                <h4 className="text-black entry-title">Gallery</h4>
                            </div>
                            <div className="scrollable-content mt-5">
                                {galleryImagesQuery.isLoading ? (
                                    <p style={{ marginTop: '30px' }}>
                                        <b>Loading...</b>
                                    </p>
                                ) : galleryImagesQuery.data.length === 0 ? (
                                    <p style={{ marginTop: '30px' }}>
                                        <b>No gallery images available</b>
                                    </p>
                                ) : (
                                    <ImageGallery items={images} />
                                )}
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="section-headings">
                                <h4 className="text-black entry-title">Monthly Newsletter</h4>
                            </div>
                            {newsletterDataQuery.isLoading ? (
                                <p style={{ marginTop: '30px' }}>
                                    <b>Loading...</b>
                                </p>
                            ) : (
                                <div className="scrollable-content mt-5" id="newsletterprog">
                                    {filtereddata.length > 0 ? (
                                        filtereddata?.map((newsletter) => (
                                            <div
                                                key={newsletter.id}
                                                className="card shadow mb-2"
                                                style={{ maxWidth: '585px', height: '100px' }}
                                            >
                                                <div className="card-body text-center">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <img
                                                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/391px-PDF_file_icon.svg.png"
                                                            alt="PDF Icon"
                                                            className="mb-3"
                                                            style={{ width: '50px' }}
                                                        />
                                                        <div className="text-center">
                                                            <h5 className="card-title mb-2">
                                                                {newsletter.title}
                                                            </h5>
                                                            <p className="card-text mb-3">{newsletter.eventdate}</p>
                                                        </div>
                                                        <a
                                                            href={newsletter.file_url}
                                                            className="btn btn-sm btn-primary"
                                                            download
                                                        >
                                                            Download
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p style={{ marginTop: '30px' }}>
                                            <b>No Newsletter available</b>
                                        </p>
                                    )}

                                    {filtereddata.length >= 5 && (
                                        <center>
                                            <Link
                                                to={'/newsletter'}
                                                style={{
                                                    backgroundColor: '#012c6d',
                                                    color: 'white',
                                                    textDecoration: 'none',
                                                    display: 'inline-block',
                                                    padding: '8px 16px',
                                                    borderRadius: '5px',
                                                    marginTop: '10px',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                View More
                                            </Link>
                                        </center>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default GalleryNews;

