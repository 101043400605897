import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import MainLayout from "./layout";
import ContactUs from "./SubPages/Contact Us/ContactUs";
import BirthdayCalendar from "./SubPages/Calendars/BirthdayCalendar";
import EventCalendar from "./SubPages/Calendars/EventCalendar";
import Whoweare from "./SubPages/AboutUs/Whoweare";
import MaryWard from "./SubPages/AboutUs/MaryWard";
import TeresaBall from "./SubPages/AboutUs/TeresaBall";
import DelphineHart from "./SubPages/AboutUs/DelphinHart";
import GospelFoundation from "./SubPages/SpiritualCharism/GospelFoundation";
import Ministry from "./SubPages/MissionSongs/Ministry";
import HousePrayer from "./SubPages/SpiritualCharism/HousePrayer";
import LoretoSister from "./SubPages/BeInvolved/LoretoSister";
import NotFound from "./NotFound";
import Songs from "./SubPages/MissionSongs/Songs";
import LoretoGlobally from "./SubPages/LoretoGlobal/LoretoGlobally";
import Jpic from "./SubPages/Jpic/Jpic";
import NewsFromUN from "./SubPages/LoretoGlobal/NewsFromUN";
import ProvinceCalendar from "./SubPages/Calendars/ProvinceCalendar";
import Fcra from "./SubPages/LoretoGlobal/Fcra";
import ProvinceNewsLetter from "./SubPages/NewsLetter/ProvinceNewsLetter";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/*" element={<MainLayout />}>
          {/* About Section Routing */}
          <Route path="aboutus" element={<Whoweare />} />
          <Route path="institute-team" element={<Whoweare />} />
          <Route path="province-team" element={<Whoweare />} />
          <Route path="history" element={<Whoweare />} />
          <Route path="pioneers-mary-ward" element={<MaryWard />} />
          <Route path="teresaball" element={<TeresaBall />} />
          <Route path="delphine-hart" element={<DelphineHart />} />
          <Route path="loreto-india" element={<Whoweare />} />
          <Route path="loreto-foundation-india" element={<Whoweare />} />
          <Route path="archive" element={<Whoweare />} />

          {/* Spiritual and Charism Section Routing */}
          <Route path="spiritual" element={<GospelFoundation />} />
          <Route path="mary-ward-spiritual" element={<GospelFoundation />} />
          <Route path="ignation-spiritual" element={<GospelFoundation />} />
          <Route path="house-prayers" element={<HousePrayer />} />

          {/* Loreto Globally Section Routing */}
          <Route path="loreto-global" element={<LoretoGlobally />} />
          <Route path="mary-ward-international" element={<LoretoGlobally />} />
          <Route path="lay-associates" element={<LoretoGlobally />} />
          <Route path="useful-links" element={<LoretoGlobally />} />
          <Route path="news-un" element={<NewsFromUN />} />
          <Route path="fcra" element={<Fcra />} />

          {/* News Letter Section Routing */}
          <Route path="newsletter" element={<ProvinceNewsLetter />} />

          {/* JPIC Section Routing */}
          <Route path="jpic" element={<Jpic />} />

          {/* Mission Ministry Song Section Routing */}
          <Route path="education" element={<Ministry />} />
          <Route path="social-ministry" element={<Ministry />} />
          <Route path="spiritual-ministry" element={<Ministry />} />
          <Route path="songs" element={<Songs />} />

          {/* Be Involved Sister Section Routing */}
          <Route path="become-loreto-sister" element={<LoretoSister />} />

          <Route path="birthdaycalendar" element={<BirthdayCalendar />} />
          <Route path="eventcalendar" element={<EventCalendar />} />
          <Route path="provincecalendar" element={<ProvinceCalendar />} />
          <Route path="contactus" element={<ContactUs />} />

          {/* 404 Page */}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
