import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const NotFound = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    const handleSearch = (event) => {
        event.preventDefault();
        if (searchQuery.trim() !== '') {
            navigate(`/${searchQuery}`);
        }
    };

    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <>
            <section className="gap page-404">
                <div
                    className="parallax"
                    style={{ backgroundImage: "url(assets/images/error.webp)" }}
                />
                <div className="container">
                    <div className="search-error text-center">
                        <h2 className="font-bold">
                            4<span className="position-relative theme-dark-clr" style={{ color: "#800000" }}>0</span>4
                        </h2>
                        <h3 className="font-bold">Ooops! Page Not Found</h3>
                        <p className="w-40 m-auto">
                            Something's only missing, it could be much worse. It happens to the best
                            of us.
                        </p>
                        <form className="w-45 m-auto" onSubmit={handleSearch}>
                            <input
                                type="text"
                                name="text"
                                placeholder="Search Keyword..."
                                value={searchQuery}
                                onChange={handleInputChange}
                            />
                            <button type="submit">
                                <svg
                                    version="1.1"
                                    id="searchh"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 513.28 513.28"
                                    style={{ enableBackground: "new 0 0 513.28 513.28" }}
                                    xmlSpace="preserve"
                                >
                                    <g>
                                        <g>
                                            <path d="M495.04,404.48L410.56,320c15.36-30.72,25.6-66.56,25.6-102.4C436.16,97.28,338.88,0,218.56,0S0.96,97.28,0.96,217.6 s97.28,217.6,217.6,217.6c35.84,0,71.68-10.24,102.4-25.6l84.48,84.48c25.6,25.6,64,25.6,89.6,0 C518.08,468.48,518.08,430.08,495.04,404.48z M218.56,384c-92.16,0-166.4-74.24-166.4-166.4S126.4,51.2,218.56,51.2 s166.4,74.24,166.4,166.4S310.72,384,218.56,384z" />
                                        </g>
                                    </g>
                                    <g> </g>
                                    <g> </g>
                                    <g> </g>
                                    <g> </g>
                                    <g> </g>
                                    <g> </g>
                                    <g> </g>
                                    <g> </g>
                                    <g> </g>
                                    <g> </g>
                                    <g> </g>
                                    <g> </g>
                                    <g> </g>
                                    <g> </g>
                                    <g> </g>
                                </svg>
                            </button>
                        </form>
                        <div className="d-flex justify-content-center loadmore">
                            <Link to={'/'} className="theme-btn">
                                Back to Homepage
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default NotFound;
