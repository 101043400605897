import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./layout/partials/Header";
import Slider from "./Components/Slider";
import RecentBlog from "./Components/RecentBlog";
import Footer from "./layout/partials/Footer";
import { ApiUrl } from "./Components/API/Api";
import AboutUs from "./Components/AboutUs";
import GalleryNews from "./Components/GalleryNews";
import Scrollbar from "./Components/FlashNews";
import OurMember from "./Components/OurMembers";

const Home = () => {
  const [homedata, setHomedata] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/get/homepagee/sections`);
        localStorage.setItem("HomeData", JSON.stringify(response?.data?.data));
        setHomedata(response?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {isLoading && (
        <div className="preloader" id="preloader">
          <svg viewBox="0 0 1920 1080" preserveAspectRatio="none" version="1.1">
            <path d="M0,0 C305.333333,0 625.333333,0 960,0 C1294.66667,0 1614.66667,0 1920,0 L1920,1080 C1614.66667,1080 1294.66667,1080 960,1080 C625.333333,1080 305.333333,1080 0,1080 L0,0 Z"></path>
          </svg>
          <div className="inner">
            <canvas
              className="progress-bar"
              id="progress-bar"
              width={200}
              height={200}
            />
            <figure>
              <img
                src="assets/images/home/logo.png"
                alt="loader"
                id="homebtnnewleft"
              />
            </figure>
            <small>Loading . . .</small>
          </div>
        </div>
      )}

      {!isLoading && (
        <>
          <Header menudata={homedata?.headermenudata} />
          <Slider sliderdata={homedata?.SlidesData} />
          <Scrollbar />
          <AboutUs />
          <OurMember />
          <RecentBlog />
          <GalleryNews />
          <br />
          <Footer />
        </>
      )}
    </>
  );
};

export default Home;
