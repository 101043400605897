import React, { useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import axios from "axios";
import { useQuery } from "react-query";
import { ApiUrl } from "./API/Api";

const imageStyles = {
    borderRadius:"50%",
    width: "207px",
    margin:"auto"
};

const imageStyles1 = {
    borderRadius:"50%",
    width: "207px",
    margin:"auto"

};

const boxStyles1 = {
    boxShadow: "0px 7px 16px 0px rgba(172 ,153 ,153, 0.5)",
    padding: "7px 25px 9px 22px",
    backgroundColor: "#fff",
};

const OurMember = () => {
    const [activeTab1, setActiveTab1] = useState("Kate Myers");
    const [activeTab2, setActiveTab2] = useState("Sr. A. Sahaya Anbu Anitha");

    const { data: tabData, isLoading } = useQuery("tabData", async () => {
        const response = await axios.get(`${ApiUrl}/get/team/11`);
        return response.data.teams;
    }, {
        refetchOnWindowFocus: false,
        retry: 0,
        staleTime: 0,
        cacheTime: 0,
        onError: (error) => {
            console.error('Error fetching data:', error);
            return error;
        }
    });

    const { data: tabData1, isLoading1 } = useQuery("tabData1", async () => {
        const response = await axios.get(`${ApiUrl}/get/team/12`);
        return response.data.teams;
    }, {
        refetchOnWindowFocus: false,
        retry: 0,
        staleTime: 0,
        cacheTime: 0,
        onError: (error) => {
            console.error('Error fetching data:', error);
            return error;
        }
    });

    const handleTabSelect1 = (selectedTab) => {
        setActiveTab1(selectedTab);
    };

    const handleTabSelect2 = (selectedTab) => {
        setActiveTab2(selectedTab);
    };

    return (
        <section className="gap fellowship">
            <div className="container-fluid py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <div style={boxStyles1}>
                                <Tab.Container activeKey={activeTab1} onSelect={handleTabSelect1}>
                                    <div className="section-headings">
                                        <h4 className="entry-title">Institute Leadership</h4>
                                    </div>
                                    <div className="entry-content">
                                        <div className="row">
                                            <div className="col-md-7 col-lg-6">
                                                <Nav variant="pills" className="flex-column mt-4">
                                                    {isLoading ? (
                                                        <p>Loading...</p>
                                                    ) : (
                                                        tabData && tabData.length > 0 ? (
                                                            tabData.map((item) => (
                                                                <Nav.Item key={item.title}>
                                                                    <Nav.Link
                                                                        eventKey={item.title}
                                                                        className={`nav-link mb-3 p-3 shadow ${activeTab1 === item.title ? "active" : ""
                                                                            }`}
                                                                        style={{
                                                                            fontFamily: "Arial, sans-serif",
                                                                            fontSize: "14px",
                                                                            fontWeight: "bold",
                                                                            transition: "color 0.3s ease",
                                                                        }}
                                                                    >
                                                                        {item.role}
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            ))
                                                        ) : (
                                                            <p>No data available</p>
                                                        )
                                                    )}
                                                </Nav>
                                            </div>
                                            <div className="col-md-5 col-lg-6">
                                                <div className="tab-image">
                                                    <img
                                                        src={
                                                            tabData && tabData.length > 0 ?
                                                                tabData.find((item) => item.title === activeTab1)?.media_url
                                                                : ''
                                                        }
                                                        alt={activeTab1}
                                                        style={imageStyles}
                                                    />
                                                    <p
                                                        style={{
                                                            marginTop: "10px",
                                                            fontWeight: "bold",
                                                            marginRight:"50px",
                                                            textAlign:"center"
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: "19px",
                                                                color: "black"
                                                            }}
                                                            dangerouslySetInnerHTML={{
                                                                __html: tabData && tabData.length > 0 ?
                                                                    tabData.find(item => item.title === activeTab1)?.content
                                                                    : ''
                                                            }}
                                                        />
                                                        <p> &nbsp;{tabData && tabData.length > 0 ? tabData.find(item => item.title === activeTab1)?.role : 'No Role Found'} </p>
                                                        <br />
                                                        <span style={{ fontSize: "15px", color: "red" }}>
                                                            {activeTab1}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Container>
                            </div>
                        </div>
                        {/* 2nd tab */}
                        <div className="col-md-6 col-12">
                            <div style={boxStyles1}>
                                <Tab.Container activeKey={activeTab2} onSelect={handleTabSelect2}>
                                    <div className="section-headings">
                                        <h4 className="entry-title">Provincial Leadership</h4>
                                    </div>
                                    <div className="entry-content">
                                        <div className="row">
                                            <div className="col-md-7 col-lg-6">
                                                <Nav variant="pills" className="flex-column mt-4">
                                                    {isLoading1 ? (
                                                        <p>Loading...</p>
                                                    ) : (
                                                        tabData1 && tabData1.length > 0 ? (
                                                            tabData1.map((item) => (
                                                                <Nav.Item key={item.title}>
                                                                    <Nav.Link
                                                                        eventKey={item.title}
                                                                        className={`nav-link mb-3 p-3 shadow ${activeTab2 === item.title ? "active" : ""
                                                                            }`}
                                                                        style={{
                                                                            fontFamily: "Arial, sans-serif",
                                                                            fontSize: "14px",
                                                                            fontWeight: "bold",
                                                                            transition: "color 0.3s ease",
                                                                        }}
                                                                    >
                                                                        {item.role}
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            ))
                                                        ) : (
                                                            <p>No data available</p>
                                                        )
                                                    )}
                                                </Nav>
                                            </div>
                                            <div className="col-md-5 col-lg-6">
                                                <div className="tab-image">
                                                    <img
                                                        src={
                                                            tabData1&& tabData1.length > 0 ?
                                                                tabData1.find((item) => item.title === activeTab2)?.media_url
                                                                : ''
                                                        }
                                                        alt={activeTab2}
                                                        style={imageStyles1}
                                                    />
                                                   
                                                    <p
                                                       style={{
                                                        marginTop: "10px",
                                                        fontWeight: "bold",
                                                        marginRight:"50px",
                                                        textAlign:"center"
                                                    }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: "19px",
                                                                color: "black"
                                                            }}
                                                            dangerouslySetInnerHTML={{
                                                                __html: tabData1 && tabData1.length > 0 ?
                                                                    tabData1.find(item => item.title === activeTab2)?.content
                                                                    : ''
                                                            }}
                                                        />
                                                        <br />
                                                        <span style={{ fontSize: "15px", color: "red" }}>
                                                            {activeTab2}
                                                        </span>
                                                    </p>
                                                    <p className="mt-3 mb-0 text-center text-black" style={{fontWeight:"bold", marginRight:"50px"}}> &nbsp;{tabData1 && tabData1.length > 0 ? tabData1.find(item => item.title === activeTab2)?.role : 'No Role Found'} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurMember;
