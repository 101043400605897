import { useState, useEffect } from 'react';
import axios from 'axios';

const useCachedData = (url, pageId) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cachedData = localStorage.getItem(`cachedData_${pageId}`);
        if (cachedData) {
          setData(JSON.parse(cachedData));
          setLoading(false);
        } else {
          const response = await axios.get(url);
          setData(response?.data?.data);
          if (response.data.data.length > 0) {
            localStorage.setItem(`cachedData_${pageId}`, JSON.stringify(response.data.data));
          }
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [url, pageId]);

  return { data, loading };
};

export default useCachedData;
