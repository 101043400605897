import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
const MainLayout = () => {
  const Homedata = JSON.parse(localStorage?.getItem("HomeData"));
  return (
    <>
      <Header menudata={Homedata?.headermenudata} />
      <div style={{ minHeight: "66.5vh", padding: "10px", marginTop: "100px" }}>
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default MainLayout;
