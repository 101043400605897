import React from 'react'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
const Header = ({ menudata }) => {
const location = useLocation();
 const url = location.pathname;

    const renderMenuItems = (items) => {
        if (!items || !Array.isArray(items)) {
            return null;
        }

        return items.map((item) => (
            <li key={item.id} className={item.children ? 'menu-item-has-children' : 'menu-item'}>
                <Link className={item.url === url ? 'activemenu' : ''} to={item.url}>{item.label}</Link>
                {item.children && (
                    <ul className="sub-menu">
                        {item.children.map((child) => (
                            <li key={child.id} className={child.subchildren ? 'menu-item-has-children' : 'menu-item'}>
                                <Link to={child.url}>{child.label}</Link>
                                <ul className="sub-menu">
                                    {child.subchildren && renderMenuItems(child.subchildren)}
                                </ul>
                            </li>
                        ))}
                    </ul>
                )}
            </li>
        ));
    };

    return (
        <>
            <header className="header-one">
                <div className="top-bar">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <ul className="login">
                                    <li>
                                        <span className="theme-clr"><i className='fa fa-phone'></i></span>&nbsp;&nbsp;<a href='tel:033-4006 1156' className='footmobile'>033-4006 1156</a>
                                    </li>
                                    <li>
                                        <span className="theme-clr"><i className='fa fa-envelope'></i></span>&nbsp;&nbsp;<a href='mailto:loretosa.sec@gmail.com' className='footmobile'>loretosa.sec@gmail.com</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-2 offset-1">
                                <ul className="social-medias">
                                    <li>
                                        <a href="/">
                                            <img src="assets/images/facebook.svg" alt="facebook" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <img src="assets/images/twitter.svg" alt="twitter" style={{ width: "20px" }} />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <img src="assets/images/instagram.svg" alt="instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <img src="assets/images/home/linkedin.png" alt="linkedin" style={{ width: "20px" }} />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="desktop-nav" id="stickyHeader">
                    <div className="headercontainer">
                        <div className="row">
                            <div className="col-lg-12">
                                <nav>
                                    <div className="logo">
                                        <Link to={'/'}>
                                            <img src="assets/images/home/logo.png" alt="Logo" id='homebtnnewleft' />
                                        </Link>
                                    </div>
                                    <div className="nav-bar">
                                        <ul>
                                            {renderMenuItems(menudata)}
                                        </ul>
                                    </div>
                                    <div className="donation">
                                    </div>
                                    <div id="nav-icon4"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasScrolling"
                                        aria-controls="offcanvasScrolling">
                                        <span />
                                        <span />
                                        <span />
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* mobile view menu */}
            <div
                className="offcanvas offcanvas-start"
                data-bs-scroll="true"
                data-bs-backdrop="false"
                tabIndex={-1}
                id="offcanvasScrolling"
                aria-labelledby="offcanvasScrollingLabel"
            >
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasScrollingLabel">
                        Loreto Province - Kolkata
                    </h5>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    />
                </div>
                <div className="offcanvas-body">
                    <nav>
                        <div className="logo">
                            <Link to={'/'}>
                                <img src="assets/images/home/logo.png" alt="Logo" id='homebtnnewleft' />
                            </Link>
                        </div>
                        <div className="nav-bar">
                            <ul>
                                {renderMenuItems(menudata)}
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </>
    )
}

export default Header
