import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './newstyle.css'
import { Link } from "react-router-dom";

const RecentBlog = () => {
    const [latestNews, setLatestNews] = useState([]);
    const [provinceNews, setProvinceNews] = useState([]);
    const [birthdayData, setBirthdayData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios.get('http://testloreto.cristolive.org/api/news/province/1')
            .then(response => {
                const filteredNews = response?.data?.data.filter(newsItem => {
                    const eventDateParts = newsItem.date.split("-");
                    const eventDate = new Date(`${eventDateParts[2]}-${eventDateParts[1]}-${eventDateParts[0]}`);
                    const currentDate = new Date();
                    return eventDate >= currentDate || eventDate.toDateString() === currentDate.toDateString();
                });
                setLatestNews(filteredNews);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching latest news:', error);
                setIsLoading(false);
            });

        axios.get('http://testloreto.cristolive.org/api/news/province/1')
            .then(response => {
                const filteredProvinceNews = response?.data?.data.filter(newsItem => {
                    const eventDateParts = newsItem.date.split("-");
                    const eventDate = new Date(`${eventDateParts[2]}-${eventDateParts[1]}-${eventDateParts[0]}`);
                    const currentDate = new Date();
                    return eventDate >= currentDate || eventDate.toDateString() === currentDate.toDateString();
                });
                setProvinceNews(filteredProvinceNews);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching Province news:', error);
                setIsLoading(false);
            });

        axios
            .get(`http://testloreto.cristolive.org/api/member/province/birthday/this_month/1`)
            .then((response) => {
                if (response?.data?.data != null) {
                    const extractedData = response?.data?.data.map((item) => ({
                        membername: item.member_name,
                        dob: item.dob,
                        image: item.image,
                    }));
                    setBirthdayData(extractedData);
                    setIsLoading(false);
                }
                setIsLoading(false);
            });
    }, []);

    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.toLocaleString("default", {
        month: "long",
    });

    const upcomingBirthdays = birthdayData.filter((item) => {
        const dobParts = item.dob.split(" - ");
        const dobDay = parseInt(dobParts[0], 10);
        const dobMonth = dobParts[1].trim();
        if (dobMonth === currentMonth) {
            return dobDay >= currentDay;
        }
        return true;
    });
    const first5UpcomingBirthdays = upcomingBirthdays.slice(0, 3);

    // Check if there's any news for the current date
    const newsForCurrentDate = latestNews.find(newsItem => {
        const eventDateParts = newsItem.date.split("-");
        const eventDate = new Date(`${eventDateParts[2]}-${eventDateParts[1]}-${eventDateParts[0]}`);
        const currentDate = new Date();
        return eventDate.toDateString() === currentDate.toDateString();
    });


    return (
        <>
            <section className="gap blog">
                <div className='container-fluid' style={{ marginLeft: "1rem" }}>
                    <div className="row">

                        <div className="col-lg-4">
                            <div className='section-heading'>
                                <h4 className="text-black entry-title">News & Events</h4>
                            </div>
                            {isLoading ? (
                                <p style={{ marginTop: "30px" }}>
                                    <b>Loading...</b>
                                </p>
                            ) : (
                                <div className="scrollable-content mt-5" id="provincialprog">
                                    {latestNews.length === 0 ? (
                                        <div style={{ marginTop: "3rem" }}><b>No News available</b></div>
                                    ) : (
                                        <>
                                            {latestNews.slice(0, 3).map((newsItem, index) => (
                                                <article key={index} className="rcardnew shadow curve">
                                                    <div className="mb-3">
                                                        <img
                                                            src={newsItem.upload_image[0] || 'assets/images/noimage.webp'}
                                                            alt="noimage"
                                                            className="img-fluid"
                                                        />
                                                    </div>
                                                    <div>
                                                        <p>
                                                            <Link to={'eventcalendar'} style={{ textDecoration: "none" }}>
                                                                <strong style={{ color: "black" }}>{newsItem.name || 'Untitled'}</strong>
                                                            </Link>
                                                        </p>
                                                        <span style={{ fontSize: 14 }}>
                                                            &nbsp;<time>{newsItem.date || 'N/A'}</time>
                                                        </span>
                                                    </div>
                                                    {newsForCurrentDate && (
                                                        <img
                                                            src="assets/images/home/new.gif"
                                                            alt="Today's News GIF"
                                                            className="news-gif"
                                                        />
                                                    )}
                                                </article>
                                            ))}
                                            {latestNews.length >= 3 && (
                                                <Link
                                                    to={"eventcalendar"}
                                                    className="btn-sm"
                                                    style={{
                                                        backgroundColor: "rgb(128 0 0)",
                                                        color: "#e0ab08",
                                                        textDecoration: "none",
                                                    }}
                                                >
                                                    View More
                                                </Link>
                                            )}
                                        </>
                                    )}
                                    <br />
                                </div>
                            )}
                        </div>

                        <div className="col-lg-4">
                            <div className='section-heading'>
                                <h4 className="text-black entry-title">Provincial Program</h4>
                            </div>
                            {isLoading ? (
                                <p style={{ marginTop: "30px" }}>
                                    <b>Loading...</b>
                                </p>
                            ) : (
                                <div className="scrollable-content mt-5" id="provincialprog">
                                    {provinceNews.length === 0 ? (
                                        <div style={{ marginTop: "3rem" }}><b>No Province Program available</b></div>
                                    ) : (
                                        <>
                                            {provinceNews.slice(0, 3).map((provinceItem, index) => (
                                                <article key={index} className="rcardnew shadow curve">
                                                    <div className="mb-3">
                                                        <img
                                                            src={provinceItem.upload_image[0] || 'assets/images/noimage.webp'}
                                                            alt="noimage"
                                                            className="img-fluid"
                                                        />
                                                    </div>
                                                    <div>
                                                        <p>
                                                            <Link to={'provincecalendar'} style={{ textDecoration: "none" }}>
                                                                <strong style={{ color: "black" }}>{provinceItem.name || 'Untitled'}</strong>
                                                            </Link>
                                                        </p>
                                                        <span style={{ fontSize: 14 }}>
                                                            &nbsp;<time>{provinceItem.date || 'N/A'}</time>
                                                        </span>
                                                    </div>
                                                    {newsForCurrentDate && (
                                                        <img
                                                            src="assets/images/home/new.gif"
                                                            alt="Today's News GIF"
                                                            className="news-gif"
                                                        />
                                                    )}
                                                </article>
                                            ))}
                                            {provinceNews.length >= 3 && (
                                                <Link
                                                    to={"provincecalendar"}
                                                    className="btn-sm"
                                                    style={{
                                                        backgroundColor: "rgb(128 0 0)",
                                                        color: "#e0ab08",
                                                        textDecoration: "none",
                                                    }}
                                                >
                                                    View More
                                                </Link>
                                            )}
                                        </>
                                    )}
                                    <br />
                                </div>
                            )}
                        </div>

                        <div className="col-lg-4">
                            <div className='section-heading'>
                                <h4 className="text-black entry-title">Upcoming Birthday / Feast</h4>
                            </div>
                            <div className="scrollable-content mt-5" id="provincialprog">
                                {isLoading ? (
                                    <p style={{ marginTop: "30px" }}>
                                        <b>Loading...</b>
                                    </p>
                                ) : first5UpcomingBirthdays.length > 0 ? (
                                    first5UpcomingBirthdays.map((birthdayItem, index) => {
                                        const dobParts = birthdayItem.dob.split(" - ");
                                        const dobDay = parseInt(dobParts[0], 10);
                                        const dobMonth = dobParts[1].trim();
                                        const isBirthdayToday =
                                            dobDay === currentDay && dobMonth === currentMonth;
                                        const imageSrc = birthdayItem.image
                                            ? birthdayItem.image
                                            : "assets/images/noimage.webp";

                                        return (

                                            <article key={index} className="rcardnew shadow curve">
                                                <div className="mb-3">
                                                    <img
                                                        src={imageSrc}
                                                        alt="noimage"
                                                        className="img-fluid"
                                                    />
                                                </div>
                                                <div>
                                                    <p>
                                                        <Link to={'birthdaycalendar'} style={{ textDecoration: "none" }}>
                                                            <strong style={{ color: "black" }}>{birthdayItem.membername || 'Untitled'}</strong>
                                                        </Link>
                                                    </p>
                                                    <span style={{ fontSize: 14 }}>
                                                        &nbsp;<time>{birthdayItem.dob || 'N/A'}</time>
                                                    </span>
                                                </div>
                                                {isBirthdayToday && (
                                                    <img
                                                        src="assets/images/home/birthday.gif"
                                                        alt="Happy Birthday GIF"
                                                        className="birthday-gif"
                                                    />
                                                )}
                                            </article>
                                        );
                                    })
                                ) : (
                                    <p style={{ color: "black", fontWeight: "bold", marginTop: "3rem" }}>
                                        <br />
                                        No Birthday Wishes Available
                                    </p>
                                )}
                                <br />
                                <div className="text-center">
                                    {first5UpcomingBirthdays.length >= 3 && (
                                        <Link
                                            to={"birthdaycalendar"}
                                            className="btn-sm"
                                            style={{
                                                backgroundColor: "rgb(128 0 0)",
                                                color: "#e0ab08",
                                                textDecoration: "none",
                                            }}
                                        >
                                            View More
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default RecentBlog;
