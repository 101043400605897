import React, { useState, useEffect } from "react";
import axios from "axios";
import "./FlashNews.css";

function Scrollbar() {
    const [latestNews, setLatestNews] = useState([]);
    const [currentNewsIndex, setCurrentNewsIndex] = useState(0);

    useEffect(() => {
        axios
            .get("http://testloreto.cristolive.org/api/news/province/1")
            .then((response) => {
                const filteredNews = response?.data?.data.filter((newsItem) => {
                    const eventDateParts = newsItem.date.split("-");
                    const eventDate = new Date(
                        `${eventDateParts[2]}-${eventDateParts[1]}-${eventDateParts[0]}`
                    );
                    const currentDate = new Date();
                    return (
                        eventDate >= currentDate ||
                        eventDate.toDateString() === currentDate.toDateString()
                    );
                });
                setLatestNews(filteredNews);
            })
            .catch((error) => {
                console.error("Error fetching latest news:", error);
            });
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentNewsIndex((prevIndex) =>
                prevIndex === latestNews.length - 1 ? 0 : prevIndex + 1
            );
        }, 5000);

        return () => clearInterval(interval);
    }, [latestNews]);

    return (
        <div className="scrollbar">
            <div className="container">
                <div className="row flex-wrap justify-content-center justify-content-lg-between align-items-lg-center">
                    <div className="col-lg-2">
                        <div className="label ripple">Flash News</div>
                    </div>
                    <div className="col-lg-10">
                        <div className="marqueenews">
                            <div className="marquee">
                                {latestNews.length > 0 ? (
                                    latestNews.map((newsItem, index) => (
                                        <p
                                            key={index}
                                            className={index === currentNewsIndex ? "show" : ""}
                                        >
                                            <span>
                                                {newsItem.name}
                                                &nbsp;{index === currentNewsIndex && (
                                                    <img
                                                        src="assets/images/home/new.gif"
                                                        alt="Today's News GIF"
                                                        className="news1-gif"
                                                    />
                                                )}
                                            </span>
                                        </p>
                                    ))
                                ) : (
                                    <p>No Flash News Available</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Scrollbar;